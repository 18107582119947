import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import PageTitle from '../components/page/title'
import PageIntro from '../components/page/intro'
import RentDetailItem from '../components/rent/detail/item'

export const RentDetailPageTemplate = ({ title, intro, specs, video, gallery }) => (
  <div>
    <PageTitle title={title} />
    <PageIntro html={intro} />
    <RentDetailItem specs={specs} video={video} gallery={gallery} cta={{ link: '/kontakt', title: 'Küsi pakkumist' }} />
  </div>
)

const RentDetailPage = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter: { title, specs, video, gallery }, html: intro } = markdownRemark

  return (
    <Layout>
      <Helmet title={title} />
      <RentDetailPageTemplate
        title={title}
        intro={intro}
        specs={specs}
        video={video}
        gallery={gallery}
      />
    </Layout>
  )
}

export default RentDetailPage

export const rentDetailPageQuery = graphql`
  query RentDetailPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        specs {
          key,
          value
        }
        video
        gallery {
          name {
            childImageSharp {
              fixed(width: 1320, height: 800) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
