import React from 'react'
import { Link } from 'gatsby'

import './item.scss'

const RentDetailItem = ({ specs, video, gallery, cta: { link, title } }) => (
  <div className='b-rent-main'>
    <div className='l-main'>
      <div className='b-rent-main-info'>
        <div className='b-rent-main-info-image'>
          {
            (!gallery || gallery.length === 0) ||
              <figure style={{ backgroundImage: `url('${gallery[0].name.childImageSharp.fixed.src}')` }} />
          }
        </div>
        <div className='b-rent-main-info-data'>
          <div className='b-rent-main-info-data-title'>
            <p className='b-main-title m-3'>Tehnilised andmed</p>
          </div>
          <ul className='b-rent-data'>
            {
              specs.map(({ key, value }, idx) =>
                <li key={idx}>
                  <span>{key}</span>
                  <span>{value}</span>
                </li>
              )
            }
          </ul>
        </div>
      </div>
      <div className='b-rent-main-gallery'>
        {
          !video ||
            <div className='b-rent-main-gallery-item'>
              <div className='b-rent-main-gallery-item-video'>
                <iframe width='560' height='315' src={`https://www.youtube.com/embed/${video}`} allowFullScreen />
              </div>
            </div>
        }
        {
          (!gallery || gallery.length === 1) ||
            gallery.slice(1).map(({ name }, key) =>
              <div key={key} className='b-rent-main-gallery-item'>
                <figure style={{ backgroundImage: `url('${name.childImageSharp.fixed.src}')` }} />
              </div>
            )
        }
      </div>
      <div className='b-rent-main-button'>
        <Link to={link} className='b-main-button'>{title}</Link>
      </div>
    </div>
  </div>
)

export default RentDetailItem
